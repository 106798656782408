import * as React from 'react';
import printimg from '../Content/Images/icons/iconPrinter.png';
import calendarimg from '../Content/Images/icons/iconCalendarAppt32.png';
import shareimg from '../Content/Images/icons/iconShare.png';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import * as ConfirmationStore from '../store/Register';
import EventInfo from './EventInfo_Registrants';
import Footer from './Footer';
import { Button, Modal, ModalFooter, ModalBody } from 'reactstrap';


import PostCard from './PostCard';


type ConfirmationProps =
    ConfirmationStore.RegisterState &
    typeof ConfirmationStore.actionCreators &
    RouteComponentProps<{ cc: any }>;

export interface ListItems {
    itemValue: string;
    itemText: string;
}

const serialize = (obj: any) => {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}
const getDateTime = (): string => {
    var now = new Date();
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var day = now.getDate();
    var hour = now.getHours() % 12;
    var minute = now.getMinutes();

    var dateTime = month + '_' + day + '_' + year + '_' + (hour ? hour : 12) + ':' + minute;
    return dateTime;
}

const shareButtonStyle = `
    #at4-share {
    display: none !important;
`

export class Confirmation extends React.PureComponent<ConfirmationProps, any> {
    constructor(props: ConfirmationProps) {
        super(props);
        //var cCode = this.props.match.params["cc"];
        this.state = { EventRegistrantInfo: '', ddlState: [], display: 'none', displayPostcard: false, MessageModal: false, }

        fetch('api/ListItems/State')
            .then(response => response.json() as Promise<Array<ListItems[]>>)
            .then(list => {
                this.setState({ ddlState: list });
            }).catch(error => {
                console.log(error);
            });
        var confirmationcode = this.props.match.params.cc;// this.props.match.url.split("/")[2];
        if (confirmationcode != '' && confirmationcode !== undefined)
            this.handleConfirmationCode(confirmationcode);
        else
            this.handleConfirmationCode("");

        this.ShowPopUp = this.ShowPopUp.bind(this);
        this.ClosePopUp = this.ClosePopUp.bind(this);
        this.SendGoogleCalendar = this.SendGoogleCalendar.bind(this);
        this.SendCalendar = this.SendCalendar.bind(this);
        this.SendCalendarEmail = this.SendCalendarEmail.bind(this);
        this.ShowPostcard = this.ShowPostcard.bind(this);
    }

    componentDidMount() {

        //Adjust page height as per ISI mode
        var divISI = document.getElementById('fixed-isi');
        if (divISI != null) {
            if (divISI.classList.contains('isi-top-inactive')) {
                var divConfirmationContainer = document.getElementById('divConfirmationContainer')
                if (divConfirmationContainer != null) {
                    divConfirmationContainer.style.height = "auto";
                }
            }
        }
    }

    handleConfirmationCode(code: any) {
        if (code == "")
            code = this.props.confirmationCode;
        const queryStr = serialize({ sConfirmatiomCode: code })
        const base_url = 'api/Registration/GetEventRegistrantInfo';
        const init = { method: 'GET', accept: 'application/json', headers: {} };
        fetch(`${base_url}?${queryStr}`, init).then((response) => response.json())
            .then(responsedata => {
                this.props.setConfirmationCode(responsedata.ConfirmationCode)
                this.setState({ EventRegistrantInfo: responsedata })
            });
    }

    SendGoogleCalendar() {
        const queryStr = serialize({ sEventId: this.state.EventRegistrantInfo.EventId })
        const base_url = 'api/Registration/SendGoogleCalendar';
        const init = { method: 'POST', accept: 'application/json', headers: {} };
        fetch(`${base_url}?${queryStr}`, init).then((response) => response.text())
            .then(responsedata => {
                window.open(responsedata, '_newtab');
                setTimeout(() => this.setState({ display: 'none' }), 1000);
            }).catch(error => {
                console.log(error);
            });
    }

    SendCalendar(option: any) {
        const queryStr = serialize({ sEventId: this.state.EventRegistrantInfo.EventId, sCalendarOptions: option })
        const base_url = 'api/Registration/SendOutlookCalendar';
        const init = { method: 'POST', accept: 'application/json', headers: {} };
        fetch(`${base_url}?${queryStr}`, init).then((response) => response.blob())
            .then(blob => {
                let basename = option === '2' ? 'iCal' : 'Outlook';
                let filename = basename + '_' + getDateTime();
                filename = filename + '.ics';
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
                a.remove();
                setTimeout(() => this.setState({ display: 'none' }), 1000);
            })
    }

    SendCalendarEmail() {
        const queryStr = serialize({ sConfirmatiomCode: this.props.confirmationCode })
        const base_url = 'api/Registration/SendCalendarEmail';
        const init = { method: 'POST', accept: 'application/json', headers: {} };
        fetch(`${base_url}?${queryStr}`, init).then((response) => response.text())
            .then(responsedata => {
                setTimeout(() => this.setState({ display: 'none' }), 500);
            }).catch(error => {
                console.log(error);
            });
    }

    ShowPopUp() {
        this.setState({
            display: 'block'
        });
    }
    ClosePopUp() {
        this.setState({
            display: 'none'
        });
    }

    ShowPostcard() {
        this.setState({
            displayPostcard: true
        });
    }

    public render() {
        let contents = this.renderCreateForm();

        return <div id="divConfirmationContainer" style={{ height: '920px' }}>
            <style>{shareButtonStyle}</style>
            <div className="confirmationContents">
                {contents}
                {this.state.displayPostcard && <PostCard registrantsUniqueIdEvent={this.state.EventRegistrantInfo.UniqueIdEvent} registrantFname={this.state.EventRegistrantInfo.FirstName} registrantLname={this.state.EventRegistrantInfo.LastName} registrantZipCode={this.state.EventRegistrantInfo.ZipCode} registraintEmail={this.state.EventRegistrantInfo.Email} onSubmit={(value: any) => this.setState({ MessageModal: value })} onPostCardClose={(value: any) => this.setState({ displayPostcard: value })} />}
                <Modal isOpen={this.state.MessageModal}>
                    <ModalBody>
                        <span>Thank you! An email has been sent. We will be in touch.</span>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='secondary' onClick={() => this.setState({ MessageModal: false })}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
            <div><Footer /></div>
        </div>;
    }

    // Returns the HTML Form to the render() method.
    private renderCreateForm() {

        return (
            <div className="Confirmation-Container" >
                <div style={{ padding: "10px", width: "100%", margin: "0 auto" }}>
                    <div>
                        <EventInfo />
                    </div>

                    <div className="" style={{ borderTop: "1px solid black" }}>

                        <div className="row">
                            <div className="receiptColumn receiptColumnLeft">
                                <div style={{ marginTop: "20px" }}>
                                    <h4 style={{ color: "black", fontWeight: "bold" }}>Status: Confirmed</h4>
                                    <div style={{ float: "left" }}>
                                        <label id="lblRecieptText" style={{ color: "black" }}>{ReactHtmlParser(this.state.EventRegistrantInfo.StatusInformation)}</label>
                                    </div>

                                </div>
                            </div>
                            <div id="divReceiptColumn" className="receiptColumn receiptColumnRight">
                                <div style={{ float: "left", textAlign: "center" }} >
                                    <div className="receiptButtonsColumn">
                                        <div className="receiptButtons">
                                            <div className="receiptButtonsImage">
                                                <img src={calendarimg} alt="" />
                                            </div>
                                            <div className="receiptButtonslink">
                                                <a className="linkbutton" href="javascript:void(0)" id="aAddToCalender" onClick={this.ShowPopUp}>Add to<br />
                                                    Calendar</a>
                                            </div>
                                        </div>
                                        <div className="receiptButtons">
                                            <div className="receiptButtonsImage">
                                                <img src={printimg} alt="" />
                                            </div>
                                            <div className="receiptButtonslink">
                                                <a className="linkbutton" href="javascript:window.print();">Print This<br />
                                                    Page</a>
                                            </div>
                                        </div>

                                        <div className="receiptButtons">
                                            <div className="receiptButtonsImage">
                                                <img src={shareimg} alt="" />
                                            </div>
                                            <div className="receiptButtonslink">
                                                <a className="linkbutton" href={this.state.EventRegistrantInfo.EmailToFriend}>Email A<br />Friend</a>
                                            </div>
                                        </div>
                                        <div className="receiptButtons">
                                            <div className="receiptButtonsImage">
                                                <img src={shareimg} alt="" />
                                            </div>
                                            <div className="receiptButtonslink">
                                                <a className="linkbutton" href="javascript:void(0)" onClick={this.ShowPostcard}>Mail A<br />Postcard</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="statusForm">
                            <div className="row">
                                <div className="receiptRegistrantColumn">
                                    <div><strong style={{ color: "black" }}>Registrant Information</strong></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="receiptRegistrantColumn">
                                    <span className="fieldlabel">First Name: </span><br></br>
                                    <input type="text" name="firstname" aria-label="firstname" value={this.state.EventRegistrantInfo.FirstName} className="Confirmation-fields" disabled />
                                </div>
                                <div className="receiptRegistrantColumn">
                                    <span className="fieldlabel">Last Name: </span><br></br>
                                    <input type="text" name="lastname" aria-label="lastname" value={this.state.EventRegistrantInfo.LastName} className="Confirmation-fields" disabled />
                                </div>
                                <div className="receiptRegistrantColumn">
                                    <span className="fieldlabel">Email: </span><br></br>
                                    <input type="text" name="email" aria-label="email" value={this.state.EventRegistrantInfo.Email} className="Confirmation-fields" disabled />
                                </div>
                                <div className="receiptRegistrantColumn">
                                    <span className="fieldlabel">Phone Number: </span><br></br>
                                    <input type="text" name="phone" aria-label="phonenumber" value={this.state.EventRegistrantInfo.Phone} className="Confirmation-fields" disabled />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="receiptRegistrantColumn">
                                    <div><strong style={{ color: "black" }}>Contact Information</strong></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="receiptRegistrantColumn">
                                    <span className="fieldlabel">Address: </span><br></br>
                                    <input type="text" name="address1" aria-label="address1" value={this.state.EventRegistrantInfo.Address1} className="Confirmation-fields" disabled />
                                </div>
                                <div className="receiptRegistrantColumn">
                                    <span className="fieldlabel"></span><br></br>
                                    <input type="text" name="address2" aria-label="address2" value={this.state.EventRegistrantInfo.Address2} className="Confirmation-fields" disabled />
                                </div>
                            </div>
                            <div className="row">
                                <div className="receiptRegistrantColumn">
                                    <span className="fieldlabel">City:</span><br></br>
                                    <input type="text" name="city" aria-label="city" value={this.state.EventRegistrantInfo.City} className="Confirmation-fields" disabled />
                                </div>
                                <div className="receiptRegistrantColumn">
                                    <span className="fieldlabel">State/Province:</span><br></br>
                                    <select data-val="true" aria-label="state" value={this.state.EventRegistrantInfo.State} className="Confirmation-fields" disabled>
                                        {this.state.ddlState.map((state: any) =>
                                            <option key={state.itemValue} value={state.itemValue}>{state.itemText}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="receiptRegistrantColumn">
                                    <span className="fieldlabel">Zip/Postal Code:</span><br></br>
                                    <input type="text" name="city" aria-label="zipcode" value={this.state.EventRegistrantInfo.ZipCode} className="Confirmation-fields" disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ConfirmationPopup" id="divCalendarPopUp" style={{ display: this.state.display }}>
                    <div className="Confirm_Dialog">
                        <div className="Confirm_Dialog_Title">
                            Choose Your Calendar Receipt Method<a href="javascript:void(0)" className="closebox" onClick={this.ClosePopUp}>X</a>
                        </div>
                        <div>
                            <div className="BtnRow"><a href="javascript:void(0)" className="btn_small" onClick={this.SendGoogleCalendar}>Google Calendar</a></div>
                            <div className="BtnRow"><a href="javascript:void(0)" className="btn_small" onClick={() => this.SendCalendar('2')}>iCal Calendar</a></div>
                            <div className="BtnRow"><a href="javascript:void(0)" className="btn_small" onClick={() => this.SendCalendar('3')}>Outlook Calendar</a></div>
                            <div className="BtnRow"><a href="javascript:void(0)" className="btn_small" onClick={this.SendCalendarEmail}>E-mail</a></div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default connect(
    (state: ApplicationState) => state.register,
    ConfirmationStore.actionCreators
)(Confirmation as any);
