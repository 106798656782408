import * as React from 'react';
import { connect } from 'react-redux';
import logo from '../Content/Images/Logo/Capri_logowS4C.png';
import TranscendLogo from '../Content/Images/Logo/Transcend_White.png';

export class Footer extends React.PureComponent<any, any> {
    //static displayName = Footer.name;       

    render() {
        return (
            <div>
                <footer className="footer">
                    <div className="row">
                        <div className="column">
                            <div className="footer_capri">
                                <img src={logo} alt="CAPRI | Sign4compliance Comprehensive Medical Meeting Management System" style={{ width: "200px" }} /><br />
                                <div>
                                    <div>
                                        <span>Powered by:</span>
                                        <span className="footer_capr_sign4compliance_span">CapriMeetingManager.com</span>
                                    </div>

                                    <div className="footer_capr_sign4compliance_div">
                                        <span>Sign4Compliance.com</span>
                                    </div>
                                    <div>
                                        <span>Copyright 2024 Blue Grotto Technologies, Inc.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div>
                                <div style={{ textAlign: "center", left: "10px", fontSize: "15px", margin: "0 auto" }}>
                                    <a href="https://www.biocryst.com/terms-and-conditions/" target="_blank" style={{ color: "#ffffff" }}>Terms of Use</a>
                                    &nbsp;|&nbsp;
                                    <a href="https://orladeyo.com/cookie-policy/" target="_blank" style={{ color: "#ffffff" }}>Cookie Policy</a>
                                    &nbsp;|&nbsp;
                                    <a href="https://www.biocryst.com/global-privacy-notice/" target="_blank" style={{ color: "#ffffff" }}>Privacy Policy</a>&nbsp;&nbsp;&nbsp;
                                </div>
                                <div style={{ textAlign: "center", marginTop: "40px", fontSize: "13px", lineHeight: "18px", color: "#ffffff" }}>
                                    <div>
                                        <span>BioCryst and ORLADEYO are registered trademarks of BioCryst Pharmaceuticals, Inc.</span>
                                    </div>
                                    <div>
                                        <span>@2024 BioCryst Pharmaceuticals, Inc. All rights reserved.</span>
                                    </div>
                                </div>
                                <br></br>
                                <div style={{ border: "2px solid #d3d3d3", color: "#ffffff", width: "280px", padding: "5px 10px", fontSize: "13px", display: "none" }}>
                                    These programs are intended to provide information about the first-ever oral prophylactic therapy for people living with hereditary angioedema (HAE).                                    </div>
                            </div>
                        </div>

                        <div className="column">
                            <div className="footer_managedby">
                                <div>
                                    <span style={{ float: "left", fontSize: "12px", color: "#ffffff" }}>Managed by:</span>
                                </div>
                                <div>
                                    <img src={TranscendLogo} alt="Transcend Medical Communications" width="200" height="90" className="transcendLogo" />
                                    <br></br>
                                    <div style={{ textAlign: "center" }}>
                                        <a href="https://www.transcendmedcom.com/privacy-policy/" target="_blank" style={{ color: "#ffffff", fontSize: "12px" }}>Privacy Policy</a><br></br><br></br>
                                        <span style={{ fontSize: "12px", color: "#ffffff" }} id="spProjectCode">US.ORL.02072 6/24</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div >
        );
    }
}
export default connect()(Footer);