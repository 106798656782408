import * as React from 'react';
import { connect } from 'react-redux';
import BioCryst from '../Content/Images/Logo/BioCryst_Logo.png';
import Empower from '../Content/Images/Logo/EMPOWER_Logo.png';
import Orladeyo from '../Content/Images/Logo/Orladeyo_Logo.png';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import printimg from '../Content/Images/icons/iconPrinter.png';
import calendarimg from '../Content/Images/icons/iconCalendarAppt32.png';
import shareimg from '../Content/Images/icons/iconShare.png';
import logo from '../Content/Images/Logo/Capri_logowS4C.png';

const Inclusive = () => {
    return (

        <div>
            <div className="row" style={{ marginTop: "20px" }}>
                <div style={{ width: "40%", marginLeft: "100px", border: "1px solid black" }}>
                    <div id="home-hero" className="hero" style={{ paddingBottom: "0px", marginTop: "5px" }}>
                        <header className="header">
                            <div className="right_nav">
                                <a href="https://biocryst.com/wp-content/uploads/2020/12/ORLADEYO_PI_V1_2020.pdf" target="_blank" className="header__link">Prescribing Information</a>
                            </div>
                        </header>
                    </div>
                </div>
                <div style={{ width: "40%", border: "1px solid black" }}>
                    <div id="home-hero" className="hero" style={{ paddingBottom: "0px", marginTop: "5px" }}>
                        <header className="header">
                            <div className="right_nav">
                                <a href="https://biocryst.com/wp-content/uploads/2020/12/ORLADEYO_PI_V1_2020.pdf" target="_blank" className="header__link"><h3 style={{ textAlign: "center", color: "white" }}>Prescribing Information</h3></a>
                            </div>
                        </header>
                    </div>
                </div>
            </div>
            <div className="row">
                <div style={{ width: "40%", marginLeft: "100px", border: "1px solid black" }}>
                    <div style={{ marginLeft: "100px" }}>
                        <div>
                            <div style={{ padding: "0px 10px 0px 10px", marginTop: "10px" }}>
                                <div id="header-title" className="header__logo">
                                    <div className="row">
                                        <div className="ImgColumn">
                                            <div className="img_Empower">
                                                <img src={Orladeyo} alt="Orladeyo" className="img-header-logo" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "40%", border: "1px solid black" }}>
                    <div style={{ marginLeft: "100px" }}>
                        <div>
                            <div style={{ padding: "0px 10px 0px 10px", marginTop: "10px" }}>
                                <div id="header-title" className="header__logo">
                                    <div className="row">
                                        <div className="ImgColumn">
                                            <div className="img_Empower">
                                                <img src={Orladeyo} alt="Orladeyo (berotralstat) capsules 150 mg" className="img-header-logo" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div style={{ width: "40%", marginLeft: "100px", border: "1px solid black" }}>
                    <div style={{ marginLeft: "100px" }}>
                        <div>
                            <div style={{ padding: "0px 10px 0px 10px", marginTop: "10px" }}>
                                <div id="header-title" className="header__logo">
                                    <div className="row">
                                        <div className="ImgColumn">
                                            <div className="img_Empower">
                                                <img src={Empower} alt="Empower" className="img-header-logo" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "40%", border: "1px solid black" }}>
                    <div style={{ marginLeft: "100px" }}>
                        <div>
                            <div style={{ padding: "0px 10px 0px 10px", marginTop: "10px" }}>
                                <div id="header-title" className="header__logo">
                                    <div className="row">
                                        <div className="ImgColumn">
                                            <div className="img_Empower">
                                                <img src={Empower} alt="Empower Patient Services" className="img-header-logo" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div style={{ width: "40%", marginLeft: "100px", border: "1px solid black" }}>
                    <div className="divProgramcheckbox" style={{ marginLeft: "20px" }}>
                        <span className="programtypes">PROGRAM TYPES</span>
                        <div className="divProgramcheckbox">
                            <div>
                                <div className="columnCheckbox" style={{ width: "30%" }}>
                                    <input type="checkbox" id="liveprogram" name="liveprogram" value="live" defaultChecked />
                                    <label className="labelcheckboxes">Live Program</label>
                                </div>
                                <div className="columnCheckbox" style={{ width: "30%" }}>
                                    <input type="checkbox" id="virtualprogram" name="virtualprogram" value="virtual" defaultChecked />
                                    <label className="labelcheckboxes">Virtual Program</label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div style={{ width: "40%", border: "1px solid black" }}>
                    <div className="divProgramcheckbox" style={{ marginLeft: "20px" }}>
                        <span className="programtypes"><h3 style={{ color: "black" }}>PROGRAM TYPES</h3></span>
                        <div className="divProgramcheckbox">
                            <div>
                                <div className="columnCheckbox" style={{ width: "30%" }}>
                                    <input type="checkbox" id="liveprogram" aria-label="liveprogram" name="liveprogram" value="live" defaultChecked />
                                    <label className="labelcheckboxes">Live Program</label>
                                </div>
                                <div className="columnCheckbox" style={{ width: "30%" }}>
                                    <input type="checkbox" id="virtualprogram" aria-label="virtualprogram" name="virtualprogram" value="virtual" defaultChecked />
                                    <label className="labelcheckboxes">Virtual Program</label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div style={{ width: "40%", marginLeft: "100px", border: "1px solid black" }}>
                    <div className="togglediv">
                                    <div>
                                        <a><span id="spanSearchEventsToggle">Advanced Search<i className="down"></i></span></a>
                                    </div>
                                </div>
                    <div id="divSearchFilters" className="SearchFilters" style={{ display: "block", minHeight: "0px" }}>
                        <form >
                            <div style={{ marginLeft: "20px" }}>
                                <div className="colSearchDates" style={{ width: "50%", marginTop: "20px" }}>
                                    <div>
                                        <span className="section_title">Search Dates</span> <span className="date_red">
                                            <span style={{ color: "#9c3131", fontSize: "12px", paddingLeft: "5px" }}>12/11/2022 - 4/11/2023</span></span> <a className="halflings refresh glyphicons" href="javascript:window.location.href = window.location.href"></a>
                                    </div>
                                    <br />
                                    <div className="colDates">
                                        <table>
                                            <tr>
                                                <td>
                                                    <div className="filters_ColumnTitles"><span className="fieldlabel">From:</span> </div>
                                                    <DatePicker name="FromDate" format={"M/d/yyyy"} width="110px" defaultValue={new Date(new Date().setMonth(new Date().getMonth()))} />
                                                </td>
                                                <td>
                                                    <div className="filters_ColumnTitles"><span className="fieldlabel">To:</span> </div>
                                                    <DatePicker name="ToDate" format={"M/d/yyyy"} width="110px" defaultValue={new Date(new Date().setMonth(new Date().getMonth() + 4))} />
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div style={{ width: "40%", border: "1px solid black" }}>
                    <div className="togglediv">
                        <div>
                            <a href="#" aria-expanded="false"><span id="spanSearchEventsToggle">Advanced Search<i className="down"></i></span></a>
                        </div>
                    </div>
                    <div id="divSearchFilters" className="SearchFilters" style={{ display: "block", minHeight: "0px" }}>
                        <form >
                            <div style={{ marginLeft: "20px" }}>
                                <div className="colSearchDates" style={{ width: "50%", marginTop: "20px" }}>
                                    <div>
                                        <span className="section_title">Search Dates</span> <span className="date_red">
                                            <span style={{ color: "#9c3131", fontSize: "12px", paddingLeft: "5px" }}>12/11/2022 - 4/11/2023</span></span> <a className="halflings refresh glyphicons" href="javascript:window.location.href = window.location.href"></a>
                                    </div>
                                    <br />
                                    <div className="colDates">
                                        <table>
                                            <tr>
                                                <td>
                                                    <div className="filters_ColumnTitles"><span className="fieldlabel">From:</span> </div>
                                                    <DatePicker name="FromDate" format={"M/d/yyyy"} width="110px" defaultValue={new Date(new Date().setMonth(new Date().getMonth()))} />
                                                </td>
                                                <td>
                                                    <div className="filters_ColumnTitles"><span className="fieldlabel">To:</span> </div>
                                                    <DatePicker name="ToDate" format={"M/d/yyyy"} width="110px" defaultValue={new Date(new Date().setMonth(new Date().getMonth() + 4))} />
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row">
                <div style={{ width: "40%", marginLeft: "100px", border: "1px solid black" }}>
                    <div id="divSearchFilters" className="SearchFilters" style={{ display: "block", minHeight: "0px" }}>
                        <form >
                            <div className="columnZip colZipCodeSearch">
                                <div className="divSearchLocation">
                                    <span id="spanSearchLocation" className="section_title">Search Location</span>
                                </div>
                                <br />
                                <div>
                                    <table>
                                        <tr>
                                            <td id="tdziptext">
                                                <Input type="number" placeholder="Zip Code" id="txtZipcode" maxLength={5} style={{ border: "3px solid #83A0B0", width: "110px", fontWeight: 500 }} />
                                            </td>
                                            <td id="tdradius" className="tdradius">
                                                <div className="filters_ColumnTitles"><span className="fieldlabel">Radius:</span> </div>
                                                <DropDownList data={[{ text: '10 Miles', Value: 10 }, { text: '25 Miles', Value: 25 }, { text: '60 Miles', Value: 60 }]}
                                                    textField="text" style={{ width: "110px" }}
                                                    dataItemKey="Value" defaultValue={{ text: '10 Miles', Value: 10 }} />
                                            </td>
                                            <td className="td_btn_search">
                                                <button type="submit" className="k-primary k-button homeSearchButton"> Search </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div style={{ width: "40%", border: "1px solid black" }}>
                    <div id="divSearchFilters" className="SearchFilters" style={{ display: "block", minHeight: "0px" }}>
                        <form >
                            <div className="columnZip colZipCodeSearch">
                                <div className="divSearchLocation">
                                    <span id="spanSearchLocation" className="section_title">Search Location</span>
                                </div>
                                <br />
                                <div>
                                    <table>
                                        <tr>
                                            <td id="tdziptext">
                                                <Input type="number" aria-describedby="" aria-roledescription="" placeholder="Zip Code" id="txtZipcode" maxLength={5} style={{ border: "3px solid #83A0B0", width: "110px", fontWeight: 500 }} />
                                            </td>
                                            <td id="tdradius" className="tdradius">
                                                <div className="filters_ColumnTitles"><span className="fieldlabel">Radius:</span> </div>
                                                <DropDownList data={[{ text: '10 Miles', Value: 10 }, { text: '25 Miles', Value: 25 }, { text: '60 Miles', Value: 60 }]}
                                                    textField="text" style={{ width: "110px" }}
                                                    dataItemKey="Value" defaultValue={{ text: '10 Miles', Value: 10 }} />
                                            </td>
                                            <td className="td_btn_search">
                                                <button type="submit" className="k-primary k-button" style={{ backgroundColor: "#007298", borderColor: "#007298" }} > Search </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row">
                <div style={{ width: "40%", marginLeft: "100px", border: "1px solid black" }}>
                    <div style={{ width: "70%", marginLeft: "75px", marginTop: "10px" }}>
                        <div className="GridContainer">
                            <Grid style={{ borderColor: "black" }}
                                data={([{
                                    EventId: "",
                                    Program: "",
                                    Location: "",
                                    Speaker: ""
                                }])}
                                pageable={false}
                                sortable={false}
                                filterable={false}
                                scrollable="none"
                            >

                                <Column headerClassName="gridHeader"
                                    field="eventId"
                                    title="Register"
                                    cell={(props) =>
                                        <td>
                                            <button style={{ backgroundColor: "#56c2b7", borderColor: "#56c2b7" }}
                                                className="k-primary k-button"
                                            > Register
                                            </button>
                                        </td>
                                    }
                                />
                                <Column headerClassName="gridHeader"
                                    field="program"
                                    title="Program"
                                    cell={(props) => <td><span><span style={{ fontWeight: "bold" }}>Test Program BGT 8</span><br></br>Thursday, December 29, 2022<br></br>2:00 PM - 3:00 PM (CT)</span></td>}
                                />
                            </Grid>
                        </div>
                        <p className="text-center"><a href="https://orladeyo.com/" target="_blank">Click here</a> to visit the ORLADEYO website to get more information prior to joining the program.</p>

                    </div>
                </div>
                <div style={{ width: "40%", border: "1px solid black" }}>
                    <div style={{ width: "70%", marginLeft: "75px", marginTop: "10px" }}>
                        <div className="GridContainer">
                            <Grid style={{ borderColor: "black" }}
                                data={([{
                                    EventId: "",
                                    Program: "",
                                    Location: "",
                                    Speaker: ""
                                }])}
                                pageable={false}
                                sortable={false}
                                filterable={false}
                                scrollable="none"
                            >

                                <Column headerClassName="gridHeader"
                                    field="eventId"
                                    title="Register"
                                    cell={(props) =>
                                        <th>
                                            <button style={{ backgroundColor: "#007298", borderColor: "#007298" }}
                                                className="k-primary k-button"
                                            > Register
                                            </button>
                                        </th>
                                    }
                                />
                                <Column headerClassName="gridHeader"
                                    field="program"
                                    title="Program"
                                    cell={(props) => <th><span><span style={{ fontWeight: "bold" }}>Test Program BGT 8</span><br></br>Thursday, December 29, 2022<br></br>2:00 PM - 3:00 PM (CT)</span></th>}
                                />
                            </Grid>
                        </div>
                        <p className="text-center"><a href="https://orladeyo.com/" target="_blank">Click here</a> to visit the ORLADEYO website to get more information prior to joining the program.</p>

                    </div>
                </div>
            </div>
            <div className="row" style={{ display:"none"}}>
                <div style={{ width: "40%", marginLeft: "100px", border: "1px solid black" }}>
                    <div style={{ width: "80%", marginLeft: "20px" }}>
                        <div>
                            <div className="divAboutPrograms" id="divAboutThePrograms">
                                <span id="aboutprograms">ABOUT PROGRAMS</span>
                                <p>This live educational program series on ORLADEYO<sup>&reg;</sup> (berotralstat) offers people living within HAE and their caregivers the opportunity to:</p>
                                <ul className="feature-list">
                                    <li>Consider how your HAE and treatments may have disrupted your life, and identify factors to think about in finding the right preventative therapy for you</li>
                                </ul>
                                <p>
                                    For questions or to register for a program, please contact:<br className="newLineMedia"></br>
                                    1-877-547-5640 or <a className="supportEmail" href="mailto:Support@ORLADEYOeventspatient.com">Support@ORLADEYOeventspatient.com</a>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "40%", border: "1px solid black" }}>
                </div>
            </div>
            <div className="row">
                <div style={{ width: "40%", marginLeft: "100px", border: "1px solid black" }}>
                    <div style={{ width: "80%", marginLeft: "20px", marginTop: "10px" }}>
                        <div>
                            <div><span style={{ fontSize: "14px" }}><strong>ORLADEYO<sup>&reg;</sup> (berotralstat) Live Program</strong></span></div>
                            <div style={{ paddingBottom: "5px" }}><span style={{ fontSize: "14px" }}><strong>Program Information</strong></span></div>
                            <div style={{ display: "flex" }}>
                                <div className="eventinfoColumn" style={{ width: "50%" }}>
                                    <span>Date & Time</span>
                                </div>
                                <div className="eventinfoColumn" style={{ width: "50%" }}>
                                    <span>Location</span>
                                </div>
                            </div>
                            <div style={{ display: "flex" }}>
                                <div className="eventinfoValueColumn" style={{ width: "50%" }}>Thursday, December 29, 2022
                                    2:00 PM - 3:00 PM (CT)</div>
                                <div className="eventinfoValueColumn" style={{ width: "50%" }}>Webinar Program<br></br>From the comfort of your home or office</div>
                            </div >
                        </div>
                    </div>
                </div>
                <div style={{ width: "40%", border: "1px solid black" }}>
                    <div style={{ width: "80%", marginLeft: "20px", marginTop: "10px" }}>
                        <div>
                            <div><span style={{ fontSize: "14px" }}><strong><h3 style={{ color: "black" }}>ORLADEYO<sup>&reg;</sup> (berotralstat) Live Program</h3></strong></span></div>
                            <div style={{ paddingBottom: "5px" }}><span style={{ fontSize: "14px" }}><strong>Program Information</strong></span></div>
                            <div style={{ display: "flex" }}>
                                <div className="eventinfoColumn" style={{ width: "50%" }}>
                                    <span>Date & Time</span>
                                </div>
                                <div className="eventinfoColumn" style={{ width: "50%" }}>
                                    <span>Location</span>
                                </div>
                            </div>
                            <div style={{ display: "flex" }}>
                                <div className="eventinfoValueColumn" style={{ width: "50%" }}>Thursday, December 29, 2022
                                    2:00 PM - 3:00 PM (CT)</div>
                                <div className="eventinfoValueColumn" style={{ width: "50%" }}>Webinar Program<br></br>From the comfort of your home or office</div>
                            </div >
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div style={{ width: "40%", marginLeft: "100px", border: "1px solid black" }}>
                    <div style={{ width: "80%", marginLeft: "20px", marginTop: "10px", marginBottom: "10px" }}>
                        <div style={{ backgroundColor: "#007298", height: "34px", width: "100%" }}>
                            <div className="headerTitle" style={{width:"65%"}}>Event Registration</div>
                            <div className="headerHome" style={{ width: "35%" }}>
                                <a style={{ color: "#FFF", display: "block", textDecoration: "none" }} href="#" > Home</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "40%", border: "1px solid black"}}>
                    <div style={{ width: "80%", marginLeft: "20px", marginTop: "10px", marginBottom: "10px" }}>
                        <div style={{ backgroundColor: "#007298", height: "34px", width: "100%" }}>
                            <div className="headerTitle" style={{ width: "60%", marginLeft:"20px" }}><h3 style={{ color: "white" }}>Event Registration</h3></div>
                            <div className="headerHome" style={{ width: "35%" }}>
                                <a style={{ color: "#FFF", display: "block", textDecoration: "none" }} href="#" > <h3 style={{ color: "white" }}>Home</h3></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div style={{ width: "40%", marginLeft: "100px", border: "1px solid black" }}>
                    <div style={{ width: "80%", marginLeft: "20px" }}>
                        <div className="row">
                            <div className="regColumn" style={{ width: "auto" }}>
                                <h2 className="h2Title">Registrant Information</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="regColumn" style={{ width: "auto" }}>
                                <span className="required">*</span><span className="fieldlabel1">First Name:</span>
                                <input type="text" name="firstname" className="fieldcontrol1" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="regColumn">
                                <span className="fieldlabel1">Address2:</span>
                                <input type="text" name="address2" className="fieldcontrol1" />
                            </div >
                        </div>
                        <div className="row">
                            <div className="regColumn">
                                <span className="required">*</span><span className="fieldlabel1">I am a:</span>
                                <div>
                                    <select data-val="true" name="registranttype" className="fieldcontrol1" required>
                                        <option value="" selected>Select Registrant Type</option>
                                        <option value="Caregiver">Caregiver</option>
                                    </select>
                                    <table><tbody>
                                        <tr>
                                            <td id="tdRegistrantType" style={{ paddingTop: "10px", display: "none" }}>
                                                <input id="txtRegistrantTypeOther" type="text" name="registranttypeother" className="fieldcontrol1" />
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                            </div >
                        </div>
                    </div>
                </div>
                <div style={{ width: "40%", border: "1px solid black" }}>
                    <div style={{ width: "80%", marginLeft: "20px" }}>
                        <div className="row">
                            <div className="regColumn" style={{ width: "auto" }}>
                                <h2 className="h2Title">Registrant Information</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="regColumn" style={{ width: "auto" }}>
                                <span className="required">*</span><span className="fieldlabel1">First Name:</span>
                                <input type="text" id="namedFirstName" aria-label="firstname" className="fieldcontrol1" style={{ borderColor: "black" }} required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="regColumn">
                                <span className="fieldlabel1">Address2:</span>
                                <input type="text" name="address2" aria-label="Address2"
                                    aria-required="false" className="fieldcontrol1" style={{ borderColor: "black" }} />
                            </div >
                        </div>
                        <div className="row">
                            <div className="regColumn">
                                <span className="required">*</span><label className="fieldlabel1" style={{ marginBottom: "auto" }}>I am a:</label>
                                <div>
                                    <select data-val="true" name="registranttype" aria-label="I am a:"  className="fieldcontrol1" style={{ borderColor: "black" }} required>
                                        <option value="" selected>Select Registrant Type</option>
                                        <option value="Caregiver">Caregiver</option>
                                    </select>
                                    <table><tbody>
                                        <tr>
                                            <td id="tdRegistrantType" style={{ paddingTop: "10px", display: "none" }}>
                                                <input id="txtRegistrantTypeOther" type="text" name="registranttypeother" className="fieldcontrol1" />
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                            </div >
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div style={{ width: "40%", marginLeft: "100px", border: "1px solid black" }}>
                    <div style={{ width: "80%", marginLeft: "20px" }}>
                        <div style={{ textAlign: "center", padding: "10px" }}>
                            <span style={{ fontStyle: "italic", textAlign: "center" }}><span className="required">*</span>Required field (Enter N/A if not applicable)</span>
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                            <div style={{ textAlign: "center" }}>
                                <button type="submit" className="link-button6">Register</button>&nbsp;
                                <button className="link-button6-grey" >Cancel</button>
                            </div >
                        </div>
                    </div>
                </div>
                <div style={{ width: "40%", border: "1px solid black" }}>
                    <div style={{ width: "80%", marginLeft: "20px" }}>
                        <div style={{ textAlign: "center", padding: "10px" }}>
                            <span style={{ fontStyle: "italic", textAlign: "center" }}><span className="required">*</span>Required field (Enter N/A if not applicable)</span>
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                            <div style={{ textAlign: "center" }}>
                                <button type="submit" className="link-button6">Register</button>&nbsp;
                                <button className="link-button6-grey" style={{ backgroundColor:"#8a8a8a" }} >Cancel</button>
                            </div >
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div style={{ width: "40%", marginLeft: "100px", border: "1px solid black" }}>
                    <div style={{ backgroundColor: "#007298", height: "34px", width: "100%" }}>
                        <div className="headerTitle">
                            Registration Confirmation
                        </div>
                    </div>
                </div>
                <div style={{ width: "40%", border: "1px solid black" }}>
                    <div style={{ backgroundColor: "#007298", height: "34px", width: "100%" }}>
                        <div className="headerTitle">
                            <h3 style={{ textAlign: "center", color: "white" }}>Registration Confirmation</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div style={{ width: "40%", marginLeft: "100px", border: "1px solid black" }}>
                    <div style={{ width: "80%", marginLeft: "20px" }}>
                        <div>
                            <div className="row">
                                <div className="receiptColumn receiptColumnLeft">
                                    <div style={{ marginTop: "20px" }}>
                                        <h4 style={{ color: "black", fontWeight: "bold" }}>Status: Confirmed</h4>
                                        <div style={{ float: "left" }}>
                                            <label id="lblRecieptText" style={{ color: "black" }}>{<p style={{ padding: "5px;" }}>Thank you for registering! Your attendance has been confirmed, <b>For hereditary angioedema (HAE), This Is Big: Learn About the First and Only Targeted Oral Prophylactic Therapy</b> educational program sponsored by BioCryst Pharmaceuticals.Please email <a href="mailto:Support@ORLADEYOeventspatient.com" style={{ color: "rgb(0, 120, 171);" }}>Support@ORLADEYOeventspatient.com</a>.</p>}</label>
                                        </div>

                                    </div>
                                </div>
                                <div id="divReceiptColumn" className="receiptColumn receiptColumnRight">
                                    <div style={{ float: "left", textAlign: "center" }} >
                                        <table className="receiptButtonsColumn">
                                            <tr>
                                                <td>
                                                    <img src={calendarimg} alt="Calendar" />
                                                </td>
                                                <td>
                                                    <a className="linkbutton" id="aAddToCalender">Add to<br />
                                                        Calendar</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img src={printimg} alt="Print" />
                                                </td>
                                                <td>
                                                    <a className="linkbutton" href="javascript:window.print();">Print This<br />
                                                        Page</a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <img src={shareimg} alt="Share" />
                                                </td>
                                                <td>
                                                    <a className="linkbutton" href={"#"}>Email A<br />Friend</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img src={shareimg} alt="Share" />
                                                </td>
                                                <td>
                                                    <a className="linkbutton">Mail A<br />Postcard</a>
                                                </td>
                                            </tr>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "40%", border: "1px solid black" }}>
                    <div>
                        <div className="row">
                            <div className="receiptColumn receiptColumnLeft" style={{ width: "59%" }}>
                                <div style={{ marginTop: "20px" }}>
                                    <h4 style={{ color: "black", fontWeight: "bold" }}>Status: Confirmed</h4>
                                    <div style={{ float: "left" }}>
                                        <label id="lblRecieptText" style={{ color: "black" }}>{<p style={{ padding: "5px;" }}>Thank you for registering! Your attendance has been confirmed, <b>For hereditary angioedema (HAE), This Is Big: Learn About the First and Only Targeted Oral Prophylactic Therapy</b> educational program sponsored by BioCryst Pharmaceuticals.Please email <a href="mailto:Support@ORLADEYOeventspatient.com" style={{ color: "rgb(0, 120, 171);" }}>Support@ORLADEYOeventspatient.com</a>.</p>}</label>
                                    </div>

                                </div>
                            </div>
                            <div id="divReceiptColumn" className="receiptColumn receiptColumnRight" style={{ width:"40%" }}>
                                <div style={{ float: "left", textAlign: "center" }} >
                                    <table className="receiptButtonsColumn">
                                        <tr>
                                            <td>
                                                <img src={calendarimg} alt="" />
                                            </td>
                                            <td>
                                                <a className="linkbutton" id="aAddToCalender" href={"#"}>Add to<br />
                                                    Calendar</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src={printimg} alt="" />
                                            </td>
                                            <td>
                                                <a className="linkbutton" href="javascript:window.print();">Print This<br />
                                                    Page</a>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <img src={shareimg} alt="" />
                                            </td>
                                            <td>
                                                <a className="linkbutton" href={"#"}>Email A<br />Friend</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src={shareimg} alt="" />
                                            </td>
                                            <td>
                                                <a className="linkbutton" href={"#"}>Mail A<br />Postcard</a>
                                                <a className="linkbutton" href={"#"}>Mail A<br />Postcard</a>
                                            </td>
                                        </tr>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ display:"none" }}>
                <div style={{ width: "40%", marginLeft: "100px", border: "1px solid black" }}>
                    <div style={{ width: "80%", marginLeft: "20px", marginTop: "10px" }}>
                        <div>
                            <div className="statusForm">
                                <div className="row">
                                    <div className="receiptRegistrantColumn" style={{ width: "auto" }}>
                                        <div><strong style={{ color: "black" }}>Registrant Information</strong></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="receiptRegistrantColumn" style={{ marginTop: "5px" }}>
                                        <span className="fieldlabel">First Name: </span><br></br>
                                        <input type="text" name="firstname" value={"Adam"} className="Confirmation-fields" disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "40%", border: "1px solid black" }}>
                </div>
            </div>
            <div className="row" style={{ display:"none"}}>
                <div style={{ width: "40%", marginLeft: "100px", border: "1px solid black" }}>
                    <div style={{ width: "80%", marginLeft: "20px" }}>
                        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                            <footer className="footer" style={{ minHeight: "35px" }}>
                                <div className="row">

                                    <div>

                                        <div>
                                            <div style={{ textAlign: "center", left: "10px", fontSize: "15px", margin: "0 auto" }}>
                                                <a href="https://biocryst.com/terms-conditions/" target="_blank" style={{ color: "#ffffff" }}>Terms of Use</a>
                                                &nbsp;|&nbsp;
                                                <a href="https://orladeyo.com/cookie-policy/" target="_blank" style={{ color: "#ffffff" }}>Cookie Policy</a>
                                                &nbsp;|&nbsp;
                                                <a href="https://www.biocryst.com/privacy-notice/" target="_blank" style={{ color: "#ffffff" }}>Privacy Policy</a>&nbsp;&nbsp;&nbsp;
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
                <div style={{ width: "40%", border: "1px solid black" }}>
                </div>
            </div>
            <div className="row">
                <div style={{ width: "40%", marginLeft: "100px", border: "1px solid black" }}>
                    <footer className="footer">
                        <div className="column" style={{ width:"60%" }}>
                        <table className="footer_capri">
                            <tbody>
                                <tr>
                                    <td>
                                        <img src={logo} alt="Capri Logo" style={{ width: "200px" }} /><br />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Powered by:</td>
                                                    <td>CapriMeetingManager.com</td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td>Sign4Compliance.com</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>Copyright 2022 Blue Grotto Technologies, Inc.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </footer>
                </div>
                <div style={{ width: "40%", border: "1px solid black" }}>
                    <footer className="footer">
                        <div className="column" style={{ width: "60%" }}>
                            <table className="footer_capri">
                                <tbody>
                                    <tr>
                                        <td>
                                            <img src={logo} alt="Capri Sign4Compliance Comprehensive Medical Meeting Management System" style={{ width: "200px" }} /><br />
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Powered by:</td>
                                                        <td>CapriMeetingManager.com</td>
                                                    </tr>

                                                    <tr>
                                                        <td></td>
                                                        <td>Sign4Compliance.com</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>Copyright 2022 Blue Grotto Technologies, Inc.</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </footer>
                </div>
            </div>
            <div className="row">
                <div style={{ width: "40%", marginLeft: "100px", border: "1px solid black" }}>
                    <div style={{ width: "80%", marginLeft: "20px" }}>
                        <div style={{ marginBottom: "50px" }}>

                        </div>
                    </div>
                </div>
                <div style={{ width: "40%", border: "1px solid black" }}>
                </div>
            </div>
        </div>
    );
}

export default connect()(Inclusive);
