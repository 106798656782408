import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import * as DefaultStore from '../store/Register';
import '@progress/kendo-theme-default/dist/all.css';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import BioCryst from '../Content/Images/Logo/BioCryst_Logo_Reg.png';
import Orladeyo from '../Content/Images/Logo/Orladeyo_Logo.png';
import Empower from '../Content/Images/Logo/EMPOWER_Logo.png';
import ReactHtmlParser from 'react-html-parser';
import Footer from './Footer';
import { Button, Modal, ModalFooter, ModalBody } from 'reactstrap';

import ProgramRequest from './ProgramRequest';

type DefaultProps =
    DefaultStore.RegisterState &
    typeof DefaultStore.actionCreators &
    RouteComponentProps<{}>;

export interface EventInfo {
    EventId: string;
    Program: string;
    Location: string;
    Speaker: string;
}

const serialize = (obj: any) => {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

const loadingPanel = (
    <div className="k-loading-mask">
        <div className="k-loading-image" role='alert' aria-label='Progressing'></div>
        <div className="k-loading-color"></div>
    </div>
);

export class Default extends React.PureComponent<DefaultProps, any> {
    constructor(props: DefaultProps) {
        super(props);
        this.handleGridDataStateChange = this.handleGridDataStateChange.bind(this);
        this.handleDropdownStateChange = this.handleDropdownStateChange.bind(this);
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.handleSearchEvents = this.handleSearchEvents.bind(this);
        this.registerClick = this.registerClick.bind(this);
        this.handleLiveEvents = this.handleLiveEvents.bind(this);
        this.handleVirtualEvents = this.handleVirtualEvents.bind(this);
        this.handleScrollClick = this.handleScrollClick.bind(this);
        this.handleTogglediv = this.handleTogglediv.bind(this);
        this.ShowRequestForm = this.ShowRequestForm.bind(this);

        this.state = {
            AllRegistrants: [],
            radius: [
                { text: '10 Miles', Value: 10 },
                { text: '25 Miles', Value: 25 }
            ],
            inputVal: "",
            value: { text: '10 Miles', Value: 10 },
            EventSearchDates: this.getFormattedDate(new Date(new Date().setMonth(new Date().getMonth()))) + ' - ' + this.getFormattedDate(new Date(new Date().setMonth(new Date().getMonth() + 4))),
            FromdefaultValue: new Date(new Date().setMonth(new Date().getMonth())),
            ToDefaultValue: new Date(new Date().setMonth(new Date().getMonth() + 4)),
            LiveEvent: 'live',
            VirtualEvent: 'virtual',
            gridDataState: {
                sort: [
                    { field: "EvtName", dir: "" }
                ],
                page: { skip: 0, take: 10 }
            },
            ShowLoader: true,
            displayProgramRequestForm: false,
            SubmitMessage: false,
        };
    }

    componentDidMount() {
        this.GetSearchEvents();

        //Zipcode
        var varZipcode = document.getElementById("txtZipcode");
        if (varZipcode != null) {
            var attZipcode = document.createAttribute("inputmode");
            attZipcode.value = "numeric";
            varZipcode.setAttributeNode(attZipcode);
        }

        //Adjust page height as per ISI mode
        var divISI = document.getElementById('fixed-isi');
        if (divISI != null) {
            divISI.style.top = "calc(100% - 245px)";
            if (divISI.classList.contains('isi-top-inactive')) {
                var divHomeHero = document.getElementById('home-hero')
                if (divHomeHero != null) {
                    divHomeHero.style.height = "auto";
                    divHomeHero.style.paddingBottom = '0px';
                }
            }
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.state.LiveEvent !== prevState.LiveEvent || this.state.VirtualEvent !== prevState.VirtualEvent) {
            this.GetSearchEvents();
            this.setState({ inputVal: '' });
        }
    }

    handleScrollClick = () => {
        var input = document.getElementById('divAboutThePrograms')
        if (input != null)
            window.scrollTo(0, input.offsetTop);
    }

    handleTogglediv = () => {
        var searchFilters = document.getElementById('divSearchFilters')
        if (searchFilters != null) {

            if (searchFilters.style.display == 'none') {
                searchFilters.style.display = 'block';
                var searchEventsToggle = document.getElementById('spanSearchEventsToggle')
                if (searchEventsToggle != null)
                    searchEventsToggle.innerHTML = ("Close Search<i class='up'></i>");
            }
            else {
                var searchFilters = document.getElementById('divSearchFilters')
                if (searchFilters != null)
                    searchFilters.style.display = 'none';
                var searchEventsToggle = document.getElementById('spanSearchEventsToggle')
                if (searchEventsToggle != null)
                    searchEventsToggle.innerHTML = ("Advanced Search<i class='down'></i>");
            }
        }
    }

    handleLiveEvents = (e: any) => {
        this.setState({ ShowLoader: true });
        var spanSearchLocation = document.getElementById('spanSearchLocation');
        var tdziptext = document.getElementById('tdziptext');
        var tdradius = document.getElementById('tdradius');
        if (e.target.checked) {
            this.setState({ LiveEvent: e.target.value });
            if (spanSearchLocation != null && tdziptext != null && tdradius != null) {
                spanSearchLocation.style.display = '';
                tdziptext.style.display = '';
                tdradius.style.display = '';
            }
        }
        else {
            this.setState({ LiveEvent: '' });
            if (spanSearchLocation != null && tdziptext != null && tdradius != null) {
                spanSearchLocation.style.display = 'none';
                tdziptext.style.display = 'none';
                tdradius.style.display = 'none';
            }
        }

    }

    handleVirtualEvents = (e: any) => {
        this.setState({ ShowLoader: true });
        if (e.target.checked) {
            this.setState({ VirtualEvent: e.target.value });
        }
        else {
            this.setState({ VirtualEvent: "" });
        }
    }

    registerClick = (e: any) => {
        this.props.setEventID(e);
        this.props.history.push("/register");
    }

    handleGridDataStateChange = (e: any) => {
        this.setState({ gridDataState: e.data });
    }

    handleDropdownStateChange = (event: any) => {
        this.setState({ value: event.target.value });
    }

    inputChangeHandler = (event: any) => {
        this.setState({ inputVal: event.target.value });
    }

    handleSearchEvents = (event: any) => {
        event.preventDefault();
        this.setState({ ShowLoader: true });
        this.GetSearchEvents();
        this.setState({ EventSearchDates: this.getFormattedDate(this.state.FromdefaultValue) + ' - ' + this.getFormattedDate(this.state.ToDefaultValue) })
    }

    GetSearchEvents() {
        const queryStr = serialize({ sEventTypeLive: this.state.LiveEvent, sEventTypeVirtual: this.state.VirtualEvent, sZipcode: this.state.inputVal, sRadius: this.state.value.Value, sFromDate: this.state.FromdefaultValue.toJSON(), sToDate: this.state.ToDefaultValue.toJSON() })
        const base_url = 'api/Registration/GetSearchEvents';
        const init = { method: 'GET', accept: 'application/json', headers: {} };

        fetch(`${base_url}?${queryStr}`, init).then((response) => response.json() as Promise<Array<EventInfo[]>>)
            .then(data => {
                this.setState({ AllRegistrants: data });
                this.setState({ ShowLoader: false });
            }).catch(error => {
                this.setState({ ShowLoader: false });
                console.log(error);
            });
    }

    getFormattedDate(date: any) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(1, '0');
        let day = date.getDate().toString().padStart(1, '0');

        return month + '/' + day + '/' + year;
    }

    ShowRequestForm() {
        this.setState({ displayProgramRequestForm: true });
    }

    public render() {
        let contents = this.renderCreateForm();

        return <div>
            {contents}
            {this.state.displayProgramRequestForm && <ProgramRequest onProgramRequestFormClose={(value: any) => this.setState({ displayProgramRequestForm: value })} onSubmit={(value: any) => this.setState({ SubmitMessage: value })} />}
            <Modal isOpen={this.state.SubmitMessage}>
                <ModalBody>
                    <span>Thank you! An email has been sent. We will be in touch.</span>
                </ModalBody>
                <ModalFooter>
                    <Button color='secondary' onClick={() => this.setState({ SubmitMessage: false })}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>;
    }

    // Returns the HTML Form to the render() method.
    private renderCreateForm() {
        const minDate = new Date("1/1/2000");
        return (
            <div id="home-hero" className="hero" >
                <header className="header">
                    <div className="right_nav">
                        <a href="https://espanol.orladeyoeventspatient.com/" target="_blank" className="header__link"><h3 style={{ color: "white" }}>Español</h3></a>
                        <a href="https://biocryst.com/wp-content/uploads/2020/12/ORLADEYO_PI_V1_2020.pdf" target="_blank" className="header__link"><h3 style={{ color: "white" }}>Prescribing Information</h3></a>
                        <a href="https://orladeyo.com/#page-isi" target="_blank" className="header__link"><h3 style={{ color: "white" }}>Important Safety Information</h3></a>
                        <h3 className="header__link" style={{ color: "white", fontSize: "16px", marginLeft: "30px" }}>This program is intended for US residents only.</h3>
                        <a className="header__link" style={{ textDecoration: "none" }} href="javascript:void(0)" onClick={this.handleScrollClick}><h3 style={{ color: "white" }}>ABOUT PROGRAMS</h3></a>
                    </div>
                </header>
                <main className="main">
                    <section className="content">
                        <div className="header__logo">
                            <div className="row">
                                <div className="ImgColumn">
                                    <div className="img_BioCryst">
                                        <img src={BioCryst} alt="BioCryst" className="img-header-logo" />
                                    </div>
                                </div>
                                <div className="ImgColumn">
                                    <div className="img_Orladeyo">
                                        <img src={Orladeyo} alt="Orladeyo (berotralstat) capsules 150 mg" className="img-header-logo" />
                                    </div>
                                </div>
                                <div className="ImgColumn">
                                    <div className="img_Empower">
                                        <img src={Empower} alt="Empower Patient Services" className="img-header-logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="shareaholic-canvas shareaholic-canvas-home" data-app="share_buttons" data-app-id="33194209"></div>
                        <div className="content__inner">
                            <div className="welcomeMessage">
                                <p>Join us for a FREE educational program about the first and only oral therapy designed specifically to prevent hereditary angioedema (HAE) attacks. Please note that all attendees must be 12 years of age or older, as well as an HAE patient or caregiver, to attend this program.</p>
                                <div className="divProgramcheckbox">
                                    <span className="programtypes"><h3 style={{ color: "black" }}>PROGRAM TYPES</h3></span>
                                    <div className="divProgramcheckbox">
                                        <div className="">
                                            <div className="columnCheckbox">
                                                <input type="checkbox" id="liveprogram" name="liveprogram" aria-label="liveprogram" value="live" defaultChecked onChange={e => this.handleLiveEvents(e)} />
                                                <label className="labelcheckboxes">Live Program</label>
                                            </div>
                                            <div className="columnCheckbox">
                                                <input type="checkbox" id="virtualprogram" name="virtualprogram" aria-label="virtualprogram" value="virtual" defaultChecked onChange={e => this.handleVirtualEvents(e)} />
                                                <label className="labelcheckboxes">Virtual Program</label>
                                            </div>
                                            <div className="columnRequestProgramButton">
                                                <a className="linkbutton" href="javascript:void(0)" onClick={this.ShowRequestForm}>Request a Program<br />in Your Area</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <br />
                                <div className="togglediv">
                                    <div>
                                        <a onClick={this.handleTogglediv} href="javascript:void(0)"><span id="spanSearchEventsToggle">Advanced Search<i className="down"></i></span></a>
                                    </div>
                                </div>
                                <div id="divSearchFilters" className="SearchFilters" style={{ display: "none" }}>
                                    <form onSubmit={this.handleSearchEvents}>
                                        <div>
                                            <div className="columnZip colSearchDates">
                                                <div>
                                                    <span className="section_title">Search Dates</span> <span className="date_red">
                                                        <span style={{ color: "#9c3131", fontSize: "12px", paddingLeft: "5px" }}>{this.state.EventSearchDates}</span></span> <a className="halflings refresh glyphicons" href="javascript:window.location.href = window.location.href"></a>
                                                </div>
                                                <br />
                                                <div className="colDates">
                                                    <div className="dateTable">
                                                        <div>
                                                            <div className="filters_ColumnTitles"><span className="fieldlabel">From:</span> </div>
                                                            <DatePicker name="FromDate" format={"M/d/yyyy"} width="110px" min={minDate} valid={(this.state.FromdefaultValue !== null && this.state.ToDefaultValue !== null)
                                                                && (this.state.FromdefaultValue.getTime() < this.state.ToDefaultValue.getTime()) && this.state.FromdefaultValue > minDate} validationMessage={(this.state.FromdefaultValue === null || this.state.FromdefaultValue < minDate) ? 'Please enter valid date' : 'From date cannot be after To date!'} defaultValue={this.state.FromdefaultValue} onChange={(e) => this.setState({ FromdefaultValue: e.target.value })} />
                                                        </div>
                                                        <div style={{ marginLeft: "3px" }}>
                                                            <div className="filters_ColumnTitles"><span className="fieldlabel">To:</span> </div>
                                                            <DatePicker name="ToDate" format={"M/d/yyyy"} width="110px" min={minDate} valid={(this.state.FromdefaultValue !== null && this.state.ToDefaultValue !== null)
                                                                && (this.state.FromdefaultValue.getTime() < this.state.ToDefaultValue.getTime()) && this.state.ToDefaultValue > minDate} validationMessage={this.state.ToDefaultValue === null || this.state.ToDefaultValue < minDate ? 'Please enter valid date' : 'To date cannot be before From date!'} defaultValue={this.state.ToDefaultValue} onChange={(e) => this.setState({ ToDefaultValue: e.target.value })} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="columnZip colZipCodeSearch">
                                                <div className="divSearchLocation">
                                                    <span id="spanSearchLocation" className="section_title">Search Location</span>
                                                </div>
                                                <br />
                                                <div>
                                                    <div>
                                                        <div className="dateTable">
                                                            <div id="tdziptext" style={{ marginTop: "10px" }}>
                                                                <Input type="number" placeholder="Zip Code" aria-label="zipcode" id="txtZipcode" value={this.state.inputVal} maxLength={5} valid={(this.state.inputVal !== null && (this.state.inputVal.length === 0 || this.state.inputVal.length === 5))} validationMessage={'Please enter valid zip code'} onChange={this.inputChangeHandler} style={{ border: "3px solid #83A0B0", width: "110px", fontWeight: 500 }} />
                                                            </div>
                                                            <div id="tdradius" className="tdradius">
                                                                <div className="filters_ColumnTitles"><span className="fieldlabel">Radius:</span> </div>
                                                                <DropDownList data={this.state.radius}
                                                                    textField="text" style={{ width: "110px" }}
                                                                    dataItemKey="Value" value={this.state.value} onChange={this.handleDropdownStateChange} />
                                                            </div>
                                                            <div className="td_btn_search">
                                                                <button type="submit" className="k-primary k-button homeSearchButton"> Search </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                                <div className="GridContainer">
                                    {this.state.ShowLoader && loadingPanel}
                                    <Grid style={{ borderColor: "black" }}
                                        data={process(this.state.AllRegistrants, this.state.gridDataState)}
                                        pageable={false}
                                        sortable={false}
                                        {...this.state.gridDataState}
                                        filterable={false}
                                        onDataStateChange={this.handleGridDataStateChange}
                                        scrollable="none"
                                    >

                                        <Column headerClassName="gridHeader"
                                            field="eventId"
                                            title="Register"
                                            cell={(props) => props.dataItem.eventId ?
                                                <td>
                                                    <button style={{ backgroundColor: "#007298", borderColor: "#007298" }}
                                                        className="k-primary k-button"
                                                        onClick={(e) => this.registerClick(props.dataItem.eventId)}
                                                    > Register
                                                    </button>
                                                </td> : <td></td>
                                            }
                                        />
                                        <Column headerClassName="gridHeader"
                                            field="program"
                                            title="Program"
                                            cell={(props) => <td><span>{ReactHtmlParser(props.dataItem.program)}</span></td>}
                                        />
                                        <Column headerClassName="gridHeader"
                                            field="location"
                                            title="Location"
                                            cell={(props) => <td><span>{ReactHtmlParser(props.dataItem.location)}</span></td>}
                                        />
                                        <Column headerClassName="gridHeader"
                                            field="speaker"
                                            title="Speaker(s)"
                                            cell={(props) => <td><span>{ReactHtmlParser(props.dataItem.speaker)}</span></td>}
                                        />
                                        <GridNoRecords>
                                            {!this.state.ShowLoader ?
                                                <div style={{ textAlign: 'left' }}><span className='norecords'>Unfortunately, there are no programs in your selected date range at this time. Please check back for program location updates or for questions, please call 1-877-547-5640 or email <a style={{ color: '#0078ab' }} href='mailto:Support@ORLADEYOeventspatient.com' >Support@ORLADEYOeventspatient.com</a></span></div> : <div></div>
                                            }
                                        </GridNoRecords>
                                    </Grid>
                                </div>
                                <p className="text-center"><a href="https://orladeyo.com/" target="_blank">Click here</a> to visit the ORLADEYO website to get more information prior to joining the program.</p>

                                <div className="divAboutPrograms" id="divAboutThePrograms">
                                    <span id="aboutprograms">ABOUT PROGRAMS</span>
                                    <p>This live educational program series on ORLADEYO<sup>&reg;</sup> (berotralstat) offers people living within HAE and their caregivers the opportunity to:</p>
                                    <ul className="feature-list">
                                        <li>Identify factors to think about in finding the right preventative therapy for you</li>
                                        <li>Learn about ORLADEYO<sup>&reg;</sup> (berotralstat), the only targeted preventative therapy for people living with hereditary angioedema (HAE) to combine proven attack prevention AND convenient oral administration</li>
                                        <li>Hear from someone living with HAE and learn about their journey and experience with ORLADEYO</li>
                                        <li>Find out how to obtain 1-on-1 support, gain access to ORLADEYO, and start therapy</li>
                                        <li>Learn about the HAEA as a support group and how you can get involved</li>
                                        <li>Have your questions answered during a Q&A session</li>
                                    </ul>
                                    <p>
                                        For questions or to register for a program, please contact:<br className="newLineMedia"></br>
                                        1-877-547-5640 or <a className="supportEmail" href="mailto:Support@ORLADEYOeventspatient.com">Support@ORLADEYOeventspatient.com</a>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <div><Footer /></div>
            </div >
        )
    }
}
export default connect(
    (state: ApplicationState) => state.register,
    DefaultStore.actionCreators
)(Default as any);
